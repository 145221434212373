import { NavParams, ModalController } from '@ionic/angular';
import { Component, OnInit, ViewChild } from '@angular/core';
import html2pdf from 'html2pdf.js';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-pdf-modal',
  templateUrl: './pdf-modal.page.html',
  styleUrls: ['./pdf-modal.page.scss'],
})
export class PdfModalPage implements OnInit {
  user: any;
  loading = false;
  @ViewChild('barChart') barChart: any;
  @ViewChild('horizontalBarChart') horizontalBarChart: any;
  @ViewChild('jovenChart') jovenChart: any;
  @ViewChild('adultoChart') adultoChart: any;
  @ViewChild('libreChart') libreChart: any;
  @ViewChild('laboralChart') laboralChart: any;
  @ViewChild('autoChart') autoChart: any;

  constructor(
    private modalController: ModalController,
    public navParams: NavParams,
  ) {
    // obtiene los parametros de la data
    this.user = this.navParams.get('user');
    this.createBarChart();
  }

  ngOnInit() {}

  async print() {
    this.loading = true;
    let area = document.getElementById('to-print-pdf');
    let options = {
      filename:
        'Mis_resultados_performance_' +
        this.user.name +
        this.user.surname +
        '.pdf',
    };
    await html2pdf().from(area).set(options).save();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  createBarChart() {
    // crea gráfico principal
    setTimeout(() => {
      // barchart
      new Chart(this.barChart.nativeElement, {
        type: 'polarArea',
        data: {
          labels: ['Creativo', 'Empático', 'Eficiente', 'Analítico'],
          datasets: [
            {
              data: [
                this.user.result.ed,
                this.user.result.ad,
                this.user.result.ai,
                this.user.result.ei,
              ],
              backgroundColor: [
                'rgba(255, 191, 25, 0.7)',
                'rgba(244, 83, 66, 0.7)',
                'rgba(115, 181, 105, 0.7)',
                'rgba(91, 145, 251, 0.7)',
              ],
            },
          ],
        },
        options: {
          legend: false,
          responsive: true,
          scale: {
            ticks: {
              beginAtZero: true,
            },
            display: false,
          },
        },
      });
      // horizontal barchart
      new Chart(this.horizontalBarChart.nativeElement, {
        type: 'horizontalBar',
        data: {
          labels: ['', 'Creativo', 'Empático', 'Eficiente', 'Analítico'],
          datasets: [
            {
              label: '',
              barPercentage: 1,
              barThickness: 'flex',
              data: [
                0,
                this.user.result.ed,
                this.user.result.ad,
                this.user.result.ai,
                this.user.result.ei,
              ],
              backgroundColor: [
                'rgba(255, 191, 25, 0.7)',
                'rgba(255, 191, 25, 0.7)',
                'rgba(244, 83, 66, 0.7)',
                'rgba(115, 181, 105, 0.7)',
                'rgba(91, 145, 251, 0.7)',
              ],
            },
          ],
        },
      });
      // joven
      new Chart(this.jovenChart.nativeElement, {
        type: 'polarArea',
        data: {
          labels: ['Creativo', 'Empático', 'Eficiente', 'Analítico'],
          datasets: [
            {
              data: [
                this.user.result.joven_ed,
                this.user.result.joven_ad,
                this.user.result.joven_ai,
                this.user.result.joven_ei,
              ],
              backgroundColor: [
                'rgba(255, 191, 25, 0.7)',
                'rgba(244, 83, 66, 0.7)',
                'rgba(115, 181, 105, 0.7)',
                'rgba(91, 145, 251, 0.7)',
              ],
            },
          ],
        },
        options: {
          legend: false,
          responsive: true,
          scale: {
            ticks: {
              beginAtZero: true,
            },
            display: false,
          },
        },
      });
      // adulto
      new Chart(this.adultoChart.nativeElement, {
        type: 'polarArea',
        data: {
          labels: ['Creativo', 'Empático', 'Eficiente', 'Analítico'],
          datasets: [
            {
              data: [
                parseInt(this.user.result.libre_ed) +
                  parseInt(this.user.result.auto_ed) +
                  parseInt(this.user.result.laboral_ed),
                parseInt(this.user.result.libre_ad) +
                  parseInt(this.user.result.auto_ad) +
                  parseInt(this.user.result.laboral_ad),
                parseInt(this.user.result.libre_ai) +
                  parseInt(this.user.result.auto_ai) +
                  parseInt(this.user.result.laboral_ai),
                parseInt(this.user.result.libre_ei) +
                  parseInt(this.user.result.auto_ei) +
                  parseInt(this.user.result.laboral_ei),
              ],
              backgroundColor: [
                'rgba(255, 191, 25, 0.7)',
                'rgba(244, 83, 66, 0.7)',
                'rgba(115, 181, 105, 0.7)',
                'rgba(91, 145, 251, 0.7)',
              ],
            },
          ],
        },
        options: {
          legend: false,
          responsive: true,
          scale: {
            ticks: {
              beginAtZero: true,
            },
            display: false,
          },
        },
      });
      // tiempo libre
      new Chart(this.libreChart.nativeElement, {
        type: 'polarArea',
        data: {
          labels: ['Creativo', 'Empático', 'Eficiente', 'Analítico'],
          datasets: [
            {
              data: [
                this.user.result.libre_ed,
                this.user.result.libre_ad,
                this.user.result.libre_ai,
                this.user.result.libre_ei,
              ],
              backgroundColor: [
                'rgba(255, 191, 25, 0.7)',
                'rgba(244, 83, 66, 0.7)',
                'rgba(115, 181, 105, 0.7)',
                'rgba(91, 145, 251, 0.7)',
              ],
            },
          ],
        },
        options: {
          legend: false,
          responsive: true,
          scale: {
            ticks: {
              beginAtZero: true,
            },
            display: false,
          },
        },
      });
      // laboral
      new Chart(this.laboralChart.nativeElement, {
        type: 'polarArea',
        data: {
          labels: ['Creativo', 'Empático', 'Eficiente', 'Analítico'],
          datasets: [
            {
              data: [
                this.user.result.laboral_ed,
                this.user.result.laboral_ad,
                this.user.result.laboral_ai,
                this.user.result.laboral_ei,
              ],
              backgroundColor: [
                'rgba(255, 191, 25, 0.7)',
                'rgba(244, 83, 66, 0.7)',
                'rgba(115, 181, 105, 0.7)',
                'rgba(91, 145, 251, 0.7)',
              ],
            },
          ],
        },
        options: {
          legend: false,
          responsive: true,
          scale: {
            ticks: {
              beginAtZero: true,
            },
            display: false,
          },
        },
      });
      // autopercepción
      new Chart(this.autoChart.nativeElement, {
        type: 'polarArea',
        data: {
          labels: ['Creativo', 'Empático', 'Eficiente', 'Analítico'],
          datasets: [
            {
              data: [
                this.user.result.auto_ed,
                this.user.result.auto_ad,
                this.user.result.auto_ai,
                this.user.result.auto_ei,
              ],
              backgroundColor: [
                'rgba(255, 191, 25, 0.7)',
                'rgba(244, 83, 66, 0.7)',
                'rgba(115, 181, 105, 0.7)',
                'rgba(91, 145, 251, 0.7)',
              ],
            },
          ],
        },
        options: {
          legend: false,
          responsive: true,
          scale: {
            ticks: {
              beginAtZero: true,
            },
            display: false,
          },
        },
      });
    }, 1000);
  }

  closeModal(selected = null): void {
    this.modalController.dismiss(selected);
  }
}
